<template>
  <div class="carousel-container">
    <!-- 左侧缩略图 -->
    <div class="left mySwiper-thumb">
      <swiper
          @swiper="setThumbsSwiper"
          :loop="false"
          :spaceBetween="10"
          :slidesPerView="4"
          :freeMode="true"
          :watchSlidesProgress="true"
          :modules="modules"
          class="mySwiper"
          :direction="'vertical'"
      >
        <swiper-slide v-for="(image, index) in images" :key="index" >
          <img :src="image" />
        </swiper-slide>
      </swiper>
    </div>

    <!-- 右侧主图 -->
    <swiper
        :loop="false"
        :spaceBetween="10"
        :navigation="true"
        :thumbs="{ swiper: thumbsSwiper }"
        :modules="modules"
        class="mySwiper mySwiper-main"
        ref="mainSwiper"
    >
      <swiper-slide v-for="(image, index) in images" :key="index">
        <img :src="image" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper-bundle.css';
import { FreeMode, Navigation, Thumbs } from 'swiper';

export default {
  name: 'ThumbnailCarousel',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    const modules = [FreeMode, Navigation, Thumbs];
    return {
      thumbsSwiper,
      setThumbsSwiper,
      modules,
    };
  },

};
</script>

<style scoped>

/*备注：
       1. 搜略图垂直布局 需增加 :direction="'vertical'" 以及修改样式
       2. 切换箭头使用的是原来的箭头，只是修改了样式，并且箭头样式只能全局修改才能生效，所以箭头样式在main.css
*/

.swiper {
  position: initial;
}
.carousel-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
}
.mySwiper-thumb {
  width: 134px;
  height: 518px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 24px 0;
}
.mySwiper-thumb .swiper-slide {
  width: 120px !important;
  height: 120px !important;
}
.mySwiper-thumb .swiper-slide img {
  width: 120px !important;
  height: 120px !important;
  border: 1px solid #c6c6c6;
}
.mySwiper-main {
  width: calc(100% - 154px);
  height: 100%;
  border: 1px solid #c6c6c6;
}
.swiper-slide img {
  width: 100%;
  display: block;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .mySwiper-thumb {
    width: 80px;
    height:200px;
  }
  .mySwiper-thumb .swiper-slide {
    width: 40px !important;
    height: 40px !important;
  }
  .mySwiper-thumb .swiper-slide img {
    width: 40px !important;
    height: 40px !important;
  }
  .mySwiper-main {
    width: calc(100% - 84px);
  }

}
</style>
