<template>

  <div v-if="isShow">
    <Loading/>
  </div>

  <div class="page-banner" :style="{ 'background-image': 'url(' + banner + ')' }">
    <div class="overlay">
      <div class="trapezoidal-block">
        <h1>{{ $t("product") }}</h1>
        <div class="small-nav">
          <router-link to="/">
            {{ $t("home") }}
          </router-link>
          <span>丨</span>
          <router-link to="/products">
            {{ $t("product") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div class="minHeight middle common-padding-100">
    <div class="details">
      <div class="details-left">
        <thumbnail-carousel :images="images"></thumbnail-carousel>
      </div>
      <div class="details-right">
         <h1>{{ title }}</h1>
        <div class="details-info">
          <div class="details-info-txt">
            <Editor :content="content"></Editor>
          </div>
          <div class="details-info-img">
            <div class="details-info-c">
              <a :href="pdf" target="_blank">
                <div class="download">
                  <span><i class="iconfont icon-a-Group106"></i></span>
                  <span>Download</span>
                  <span><i class="iconfont icon-youjiantou"></i></span>
                </div>
                <img :src="download">
              </a>
              </div>
          </div>
        </div>

        <div class="common-btn">
          <div>
            <router-link to="/inquiry" >
            {{ $t("inquiryNow") }} <span><i class="iconfont icon-youjiantou"></i></span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from 'vue'
import ThumbnailCarousel from "@/components/ThumbnailCarousel";
import Editor from "@/components/Editor";
import Loading from "@/components/loading";
import {productDetails, productCategories} from "@/network/main";

export default {
  name: 'Details',
  components: {
    ThumbnailCarousel,
    Editor,
    Loading
  },
  setup() {
    const meta = ref({
      title: "",
      keywords: "",
      description: "",
      baiduSiteVerification : ""
    });
    return {
      meta
    }
  },
  metaInfo () {
    return {
      title:"Products - OSONG ELECTRIC Co. LTD",
      meta: [
        {
          name: 'description',
          content: this.title
        }
      ],
    }
  },
  data(){
    return{
      banner:'',
      isShow:true,
      images: [],
      title:'',
      content:'',
      download:'',
      pdf:'',
      id:null,
      name:'',
    }
  },
  created() {
    this.id = this.$route.params.id; //拿到id
    this.name = this.$route.params.title;
    this.getInfo(this.id);
    this.getCate()
  },
  methods:{
    getCate(){
      productCategories().then(res =>{
        this.banner = res.data.data.banner;
      })
    },
    getInfo(id) {
      productDetails(id).then(res =>{
        this.isShow = false;
        this.title = res.data.data.title;
        this.images = res.data.data.images;
        this.content  = res.data.data.details;
        this.download = res.data.data.side_image;
        this.pdf = res.data.data.pdf;
      })
    },
  },
};
</script>

<style scoped>

</style>

